.containerSlider {
  margin: 1rem auto;
}

.sliderImg {
  max-width: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 2px black;
}

.sampleSlider [class^="swiper-button-"]::after {
  font-size: 20px;
  font-weight: bold;
  color: var(--gray);
  background: rgb(214, 207, 207);
  padding: 0.8rem;
  border-radius: 30%;
  box-shadow: 0px 0px 2px black;
}

.sliderWrapper {
  :global(.swiper-pagination-bullet) {
    background: rgb(192, 192, 192);
    box-shadow: 0px 0px 2px black;
    border-radius: 0.5rem;
    width: 1.5rem;
    height: 0.3rem;
    opacity: 1;
  }

  :global(.swiper-pagination-bullet-active) {
    background-color: var(--blueP);
    border-radius: 0.5rem;
  }
}

/* REPONSIVE */
@media (max-width: 750px) {
  .containerSlider {
    margin: 0rem auto;
  }
}

@media (max-width: 500px) {
  .sliderWrapper {
    :global(.swiper-pagination-bullet) {
      height: 0.2rem;
    }
  }

  .sampleSlider [class^="swiper-button-"]::after {
    font-size: 15px;
    padding: 0.7rem;
  }
}


@media (min-width: 1500px) {
  .containerSlider {
    max-width: 90rem;
  }
}
