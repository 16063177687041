.notFound {
  height: 100vh;
  position: relative;
  font-family: var(--type-second);
  display: flex;
  align-items: center;
  place-content: center;
}

.title {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;
  text-align: center;
}

.title span {
  color: var(--blueP);
  border-bottom: 3px solid var(--yellow);
}
