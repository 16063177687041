.wppBtn {
  position: fixed;
  bottom: 1rem;
  left: 2rem;
  z-index: 999999;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.wppBtnInvisible {
  opacity: 0;
  transform: translateY(1rem);
  pointer-events: none;
  visibility: hidden;
}

.wppBtn img {
  width: 4rem;
  filter: drop-shadow(0px 0px 2px black);
}

@media (max-width: 500px) {
  .wppBtn img {
    width: 3.5rem;
  }
}
