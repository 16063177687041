.footer {
  padding: 2rem;
  position: relative;
  background: var(--gray);
}

.footer::after {
  content: "";
  background: linear-gradient(45deg, transparent, var(--yellow), transparent);
  position: absolute;
  height: 2px;
  width: 50%;
  left: 50%;
  top: 48%;
  transform: translate(-50%, -50%);
}

.info {
  display: flex;
  gap: 3rem;
  flex-direction: column;
  place-content: center;
  align-items: center;
}

.contactList {
  display: flex;
  gap: 1.2rem;
}

.contactItem {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background: var(--yellow);
  padding: 0.3rem;
  border-radius: 0.5rem;
  transition: background 0.2s ease-in-out;
  box-shadow: 0px 0px 2px black;
}

.contactItem span {
  color: black;
}

.contactItem:hover {
  background-color: var(--yellow2);
}

.rights {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  height: 4rem;
  background: var(--yellow);
}

.clients,
.devs {
  font-size: 0.9rem;
  display: flex;
  text-align: center;
  margin: auto
}

@media (max-width: 800px) {
  .contactItem {
    font-size: 0.95rem;
  }
}

@media (max-width: 750px) {
  .contactList {
    display: grid;
    grid-template-columns: 1fr;
  }

  .footer::after {
    top: 30%;
  }

  .rights::after {
    content: "";
    height: 100%;
    width: 2px;
    background: linear-gradient(to bottom, var(--gray), var(--yellow));
    position: absolute;
    left: 50%;
  }

  .clients,
  .devs {
    font-size: 0.8rem;
  }
}

@media (max-width: 650px) {
  .clients,
  .devs {
    font-size: 0.68rem;
  }
}
