.title {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 3rem;
  border-bottom: 4px solid var(--blueP);
  color: var(--blackT);
  width: max-content;
  margin-bottom: 3rem;
  margin-top: 1rem;
}

/* IMAGES ======================================================================*/

.houseContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
}

.mainImg img {
  border-radius: 0.5rem;
  box-shadow: 0px 0px 2px black;
  margin-bottom: 2rem;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.mainImg:hover img {
  transform: scale(1.02);
}

.smallImg {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  margin-bottom: 2.5rem;
}

.smallImg4 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.smallImg img {
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: 0px 0px 2px black;
  transition: transform 0.25s ease-in-out;
}

.smallImg img:hover {
  transform: scale(1.065);
}

/* TEXT ============================================================================*/
.houseInfo h3 {
  font-size: 2rem;
  color: var(--blueD);
  position: relative;
}

.houseInfo h3::after {
  content: "";
  position: absolute;
  background: var(--yellow);
  width: 13.5rem;
  height: 1.75px;
  left: 0;
  bottom: 0;
}

.infoText {
  color: var(--blackT);
  font-size: 1.1rem;
  white-space: pre-line;
  font-size: 1.2rem;
}

.details h5 {
  font-size: 1.3rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  color: var(--blueD);
}
.details p {
  font-size: 1.2rem;
  border-bottom: 1px solid var(--blueD);
  color: var(--blueD);
  display: flex;
  justify-content: space-between;
  margin-bottom: 0rem;
  margin-top: 0.5rem;
}

.details span {
  font-weight: bold;
}

.details p:last-of-type {
  border: none;
  margin-bottom: 2rem;
}

/* RESPONSIVE ======================================================================*/
@media (max-width: 750px) {
  .houseContent {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .title {
    font-size: 2.5rem;
  }

  .details p:last-of-type {
    margin-bottom: 1rem;
  }
}

@media (max-width: 600px) {
  .title {
    font-size: 2rem;
  }

  .mainImg img {
    margin-bottom: 2rem;
  }

  .smallImg {
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 500px) {
  .title {
    font-size: 1.8rem;
    margin-bottom: 2rem;
    margin-top: 0.5rem;
  }
}
