.popUp {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  background: rgba(0, 0, 0, 0.95);
  color: white;
  z-index: 99999999999999;
  display: flex;
  align-items: center;
  place-content: center;
}

.btn {
  position: fixed;
  cursor: pointer;
  top: 1rem;
  right: 3.5rem;
  border-radius: 50%;
  border: none;
  z-index: 99;
  padding: 0.8rem;
  background-color: rgb(214, 207, 207);
}

/* SLIDER ============================================*/

.popUpSlider {
  max-width: 75%;
  border-radius: 0.5rem;
}

.sampleSlider [class^="swiper-button-"]::after {
  font-size: 25px;
  font-weight: bold;
  color: var(--gray);
  background: rgb(214, 207, 207);
  padding: 0.875rem;
  border-radius: 30%;
  box-shadow: 0px 0px 3px black;
}

.sliderWrapper {
  :global(.swiper-pagination-bullet) {
    background: rgb(192, 192, 192);
    border-radius: 0.5rem;
    width: 1rem;
    box-shadow: 0px 0px 2px black;
    height: 0.3rem;
    opacity: 1;
  }

  :global(.swiper-pagination-bullet-active) {
    background-color: var(--blueP);
    border-radius: 0.5rem;
  }
}

/* RESPONSIVE =========================================*/
@media (max-width: 750px) {
  .popUpSlider {
    max-width: 90%;
  }

  .btn {
    right: 2.1rem;
    top: 0.8rem;
  }
  .sampleSlider [class^="swiper-button-"]::after {
    font-size: 20px;
    padding: 0.875rem;
  }

  .sliderWrapper {
    :global(.swiper-pagination-bullet) {
      width: 0.875rem;
      height: 0.3rem;
    }
  }
}

@media (max-width: 600px) {
  .popUpSlider {
    max-width: 100%;
    border-radius: 0rem;
  }

  .btn {
    top: 1.25rem;
    right: 1rem;
    padding: 0.75rem;
  }
}

@media (max-width: 500px) {
  .sampleSlider [class^="swiper-button-"]::after {
    font-size: 17px;
    padding: 0.75rem;
  }

  .sliderWrapper {
    :global(.swiper-pagination-bullet) {
      width: 0.8rem;
      height: 0.3rem;
    }
  }
  .btn {
    padding: 0.675rem;
  }
}
