.header {
  height: 6rem;
  background: var(--gray);
  display: grid;
  place-content: center;
}

.headerContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  place-content: center;
}

.logo img {
  max-width: 100%;
}

.nav {
  color: var(--white);
  display: flex;
  align-items: center;
}

.navList {
  display: flex;
  transition: all 1s ease-in-out;
  gap: 1.5rem;
}

.navItem {
  text-transform: uppercase;
  font-size: 0.9rem;
  letter-spacing: 1.2px;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
}

.navItem::after {
  content: "";
  height: 1px;
  background: var(--blueP);
  position: absolute;
  width: 0%;
  bottom: -2px;
  left: 50%;
  transform: translate(-50%);
  transition: all ease-in-out 0.3s;
}

.navItem:hover::after {
  width: 100%;
}

/* MOBILE ==========================================================*/
.navMobile {
  display: flex;
  place-content: flex-end;
}

.menuMobile {
  background: transparent;
  border: 0.125rem solid var(--white);
  border-radius: 50%;
  padding: 1.5rem;
  position: relative;
  cursor: pointer;
  box-shadow: 0px 0px 3px black;
}

.menuMobileActive {
  display: flex;
  place-content: center;
  align-items: center;
}

.menuMobile::before {
  content: "";
  position: absolute;
  background: var(--white);
  color: var(--white);
  height: 2px;
  width: 1.5rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.25s ease-in-out;
  box-shadow: 0px 7px 0px currentColor, 0px -7px 0px currentColor;
}

.menuMobileActive::before {
  transform: rotate(-90deg);
  box-shadow: 0px 8px 0px currentColor, 0px -8px 0px currentColor;
  transition: 0.25s all ease-in-out;
  width: 4px;
  height: 4px;
  border-radius: 4rem;
  left: 46%;
  top: 46%;
}

.navListMobile {
  background: var(--gray);
  display: grid;
  position: fixed;
  left: 0;
  top: 11%;
  z-index: 9999999999;
  width: 100%;
  box-shadow: 0px 0px 1px black;
  padding: 1rem;
  gap: 1.5rem;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s ease-in-out;
}

.navListMobileActive {
  opacity: 1;
  visibility: visible;
}

.navItemMobile {
  display: flex;
  align-items: flex-end;
  gap: 0.45rem;
  letter-spacing: 1.1;
  position: relative;
  text-transform: capitalize;
}

.navItemMobile::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  background: linear-gradient(45deg, var(--blueP), transparent);
  bottom: 0px;
}

/* RESPONSIVE ==========================================================*/
@media (max-width: 1250px) {
  .navItem {
    font-size: 0.8rem;
  }
}

@media (max-width: 1000px) {
  .navItem {
    font-size: 0.75rem;
  }

  .navList {
    gap: 1rem;
  }
}

@media (max-width: 840px) {
  .navItem {
    font-size: 0.7rem;
  }

  .navList {
    gap: 0.75rem;
  }
}

@media (max-width: 750px) {
  .header {
    display: flex;
  }

  .headerContent {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    width: 100%;
  }

  .navList {
    display: none;
    visibility: hidden;
  }
}

@media (min-width: 1500px) {
  .headerContent {
    gap: 10rem;
  }
}
